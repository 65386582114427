"use strict";

var dropCookie = true;
var cookieDuration = 365;
var cookieName = "complianceCookie";
var cookieValue = "on";

function createCookie(name, value, days) {
	var expires;
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toGMTString();
	} else {
		expires = "";
	}
	if (dropCookie) {
		document.cookie = name + "=" + value + expires + "; path=/";
	}
}

function checkCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

function eraseCookie(name) {
	createCookie(name, "", -1);
}

function exists(selector) {
	try {
		return $(selector).length > 0;
	} catch {
		return false;
	}
}

function yes(condition) {
	return !!condition || condition == "true" || condition != "false";
}

function not(condition) {
	return !condition || condition == "false" || condition != "true";
}

function copyTextToClipboard(text) {
	var textArea = document.createElement("textarea");

	// Place in top-left corner of screen regardless of scroll position.
	textArea.style.position = "fixed";
	textArea.style.top = 0;
	textArea.style.left = 0;

	// Ensure it has a small width and height. Setting to 1px / 1em
	// doesn't work as this gives a negative w/h on some browsers.
	textArea.style.width = "2em";
	textArea.style.height = "2em";

	// We don't need padding, reducing the size if it does flash render.
	textArea.style.padding = 0;

	// Clean up any borders.
	textArea.style.border = "none";
	textArea.style.outline = "none";
	textArea.style.boxShadow = "none";

	// Avoid flash of white box if rendered for any reason.
	textArea.style.background = "transparent";

	textArea.value = text;

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		var successful = document.execCommand("copy");
		document.body.removeChild(textArea);
		return true;
	} catch (err) {
		document.body.removeChild(textArea);
		return false;
	}
}

function loadProvinces(countryId) {
	return new Promise((resolve) => {
		$.ajax({
			method: "GET",
			url: `/restful/countries/provinces?countryId=${countryId}`,
			success: function (data) {
				resolve(data.results);
			},
			error: function () {
				resolve([]);
			},
		});
	});
}

function scrollToElement(selector) {
	if (exists(selector)) {
		const offset = $(selector).offset().top;
		const headerOffset = exists("header") ? $("header").outerHeight() : 0;
		return new Promise((resolve) => {
			$("html").animate({ scrollTop: offset - headerOffset }, resolve);
		});
	} else {
		return Promise.reject();
	}
}

function getQueryParams(url = window.location.href) {
	let queryParams = {};
	let [_, params] = url.split("?");

	if (params) {
		for (let param of params.split("&")) {
			let [key, value] = param.split("=");
			if (queryParams[key] && value) {
				if (Array.isArray(queryParams[key])) {
					queryParams[key].concat(value);
				} else {
					queryParams[key] = [queryParams[key], value];
				}
			}
			queryParams[key] = value || "";
		}
	}

	return queryParams;
}

function getQueryParam(param) {
	return getQueryParams()[param] || "";
}

function setQueryParam(param, value) {
	let params = getQueryParams();
	params[param] = value;
	setQueryParams(params);
}

function setQueryParams(params) {
	let query = [];
	for (let param of Object.keys(params)) {
		if (params[param]) {
			if (Array.isArray(params[param])) {
				for (let value of params[param]) {
					query.push(`${param}=${value}`);
				}
			} else {
				query.push(`${param}=${params[param]}`);
			}
		}
	}
	if (query.length > 0) {
		window.location.search = `?${query.join("&")}`;
	} else {
		window.location.search = "";
	}
}

$(function () {
	$.validator.messages.max = jQuery.validator.format(msg["ERR_VALUE_TOO_HIGH"]);
	$.validator.messages.required = msg["ERR_MISSINGFIELDS"];
	$.validator.messages.email = msg['ERR_INVALIDMAIL'];

	$.validator.addMethod("complexPassword", function(value, element) {
		return this.optional(element) || value.length >= 8 && /[a-z]/g.test(value) && /[A-Z]/g.test(value) && /[0-9]/g.test(value);
	}, msg['MSG_PASSWORD_NOT_COMPLEX']);

	if (getUrlParam("errorCode") == "263") {
		$("#login-form").addClass("hide");
		$("#login-form-otp").removeClass("hide");
	}

	if (sessionStorage.getItem("side-popup") == null) {
		if ($("#side-popup").length > 0) {
			setTimeout(function () {
				sessionStorage.setItem("side-popup", "true");
				$("#side-popup").removeClass("hide");
			}, 20000);
		}
	}

	$("#close-side-popup").click(function () {
		$("#side-popup").addClass("hide");
	});

	$('a[href^="#"]').on("click", function (event) {
		event.preventDefault();

		const hash = $(this).attr("href");
		scrollToElement(hash)
			.then(() => (window.location.hash = hash))
			.catch(() => {});
	});

	if (window.location.hash.includes("-anchor")) {
		scrollToElement(window.location.hash.replace("-anchor", ""))
			.then(() => {})
			.catch(() => {});
	}

	if ($("#newsletter-popup").length > 0 && !checkCookie("bannerShown")) {
		setTimeout(() => {
			blockPageScroll();
			$("#newsletter-popup-close").on("click", () => {
				restorePageScroll();
				$("#newsletter-popup").addClass("hide");
				createCookie("bannerShown", true);
			});
			$("#newsletter-popup a.btn").on("click", (event) => {
				event.preventDefault();
				createCookie("bannerShown", true);
				window.location.href = $("#newsletter-popup").data("href");
			});
			$("#newsletter-popup").removeClass("hide");
		}, 20000);
	}


	$('.show-cms-script #newsletter-block').each(function () {
		const $this = $(this)
		const btn_logged = $this.find('a.btn-logged');
		const btn_not_logged = $this.find('a.btn-not-logged');

		if($('body').is('.logged')){
			btn_not_logged.addClass('hide')
		}
		else{
			btn_logged.addClass('hide')
		}
	});

    $('[data-toggle-elem]').on('click', function() {
        const elem = $(this.dataset.toggleElem);
        if (this.classList.contains('open')) {
            elem.each(function() {
                const $this = $(this);
                if ('toggleReverse' in this.dataset) {
                    $this.slideDown();
                }
                else {
                    $this.slideUp();
                }
            });
            this.classList.remove('open');
        }
        else {
            elem.each(function() {
                const $this = $(this);
                if ('toggleReverse' in this.dataset) {
                    $this.slideUp();
                }
                else {
                    $this.slideDown();
                }
            });
            this.classList.add('open');
        }
    });
});

function blockPageScroll() {
	$("body").addClass("block-scroll");
}

function restorePageScroll() {
	$("body").removeClass("block-scroll");
}

function loadScript(url) {
	return new Promise((resolve, reject) => {
		$.ajax({
			method: "GET",
			url: url,
			dataType: "script",
			async: true,
			success: () => resolve(),
			error: () => reject(),
		});
	});
}

function loadCaptcha(selector = ".g-recaptcha") {
	if (exists(selector)) {
		const recaptcha = $(selector);
		if (!recaptcha.data("loaded")) {
			loadScript("https://www.google.com/recaptcha/api.js").then(() =>
				recaptcha.data("loaded", "true")
			);
		}
	}
}

function GTMCallback(callback) {
    return (containerId) => {
        if ((!containerId || containerId.startsWith("GTM-")) && typeof callback === "function") {
            callback();
        }
    }
}

function pushGTMEventWithCallback(timeout, options) {
    const dataLayer = window.dataLayer ?? [];

    let callbackExecuted = false;
    dataLayer.push({
        ...options,
        ...(options.eventCallback
            ? {
                eventCallback: GTMCallback(() => {
                    if (!callbackExecuted) {
                        callbackExecuted = true;
                        options.eventCallback();
                    }
                })
            }
            : {}
        )
    });

    if (options.eventCallback) {
        setTimeout(() => {
            if (!callbackExecuted) {
                callbackExecuted = true;
                options.eventCallback();
            }
        }, timeout);
    }
}