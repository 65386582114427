$(function () {
	$("#show-private").on("click", function () {
		$("#subscribe-business").slideUp();
		$("#subscribe-private").slideDown();
	});

	$("#show-business").on("click", function () {
		$("#subscribe-private").slideUp();
		$("#subscribe-business").slideDown();
	});
});

// landing subscribe
if ($("#landing-virtual-tour, #virtual-tour-container").length > 0) {
	$(".header-main").addClass("hide");
	$(".top-banner").addClass("hide");
}
