$(function () {
	if (window.location.search != "") {
		if (document.getElementById("shoptype") != undefined) {
			var type = GetUrlValue("business");
			document.getElementById("shoptype").value = "type:" + type;
		}
	}

	// show hide password recovery
	$(".js-toggle-show").on("click", function (event) {
		event.preventDefault();
		var $this = $(this);

		$($this.attr("data-show")).slideDown();
		$($this.attr("data-hide")).slideUp();
	});

	// password recovery form
	$("#password-recover-form").submit(function (event) {
		event.preventDefault();

		var $form = $(this);

		$.ajax({
			type: $form.attr("method"),
			url: $form.attr("action"),
			data: $form.serialize(),
			dataType: "json",
			beforeSend: function (data) {
				$form.find(":input").prop("disabled", true);
			},
			success: function (data) {
				if (data.success) {
					showMessageBox("success", msg["MSG_RECOVEREMAILSENT"]);
				} else {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				}
			},
			failed: function (data) {
				showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
			},
			complete: function (data) {
				$form.find(":input").prop("disabled", false);
			},
		});
	});

	if (getUrlParam("utm_source") == "archiproducts-dem") {
		$("#subscribe-form").data("redirectsuccess", "/current/virtual-tour-b2c");
	}

	if ($("#subscribe-form").length > 0) {
		loadCaptcha();
	}

	// subscribe form
	$("#subscribe-form").validate({
		rules: {
			"repeat-username": { required: true, email: true, equalTo: "#username" },
			password1: { required: true, complexPassword: true },
			password2: { required: true, equalTo: "#password1" },
		},
		messages: {
			"repeat-username": { equalTo: msg["ERR_SAME_MAIL"] },
			password2: { equalTo: msg["ERR_SAME_PASSWORD"] },
		},
		errorPlacement: function (error, element) {
			if (!element.is("[type=checkbox]")) {
				error.insertAfter(element);
			}
		},
		submitHandler: function (form) {
			var $form = $(form);

			if ($("#subscribe-form").valid()) {
				$.ajax({
					type: $form.attr("method"),
					url: $form.attr("action"),
					data: $form.serializeArray(),
					dataType: "json",
					beforeSend: function (data) {
						$form.find(":input").prop("disabled", true);
					},
					success: function (data) {
						if (data.success) {
                            if (window.dataLayer) {
                                pushGTMEventWithCallback(500, {
                                    event: 'sign_up',
                                    eventCallback: GTMCallback(function() {
                                        window.location = $form.data("redirectsuccess");
                                    })
                                });
                            }
                            else {
                                window.location = $form.data("redirectsuccess");
                            }
						} else {
							showMessageBox("danger", msg[data.errors.message]);
						}
					},
					failed: function (data) {
						showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
					},
					complete: function (data) {
						$form.find(":input").prop("disabled", false);
					},
				});
			}
		},
	});

    const dateString = date => `${date.getFullYear().toString().slice(-4)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

	$("#subscribe-form [name=birth]")
        .each(function () {
            const date = new Date(Date.now());
            const currentDate = dateString(date);
            $(this).attr("max", currentDate);
        })
        .on('change', function() {
            const $this = $(this);
            const date = new Date($this.val());
            const max = new Date($this.attr('max'));
            if (date > max || Number.isNaN(date.getDate())) {
                const maxDateString = dateString(max);
                $this.val(maxDateString);
            }
        });

	$("#password-new-form-email").validate({
		rules: {
			email: {
				required: true,
				email: true,
			},
		},
		messages: {
			email: {
				required: msg["ERR_MISSINGFIELDS"],
				email: msg["ERR_INVALIDMAIL"],
			},
		},
		submitHandler: function (form) {
			const $form = $(form);

			$.ajax({
				method: $form.attr("method"),
				url: $form.attr("action"),
				data: $form.serialize(),
				dataType: "json",
				success: function (data) {
					if (data.success) {
						showLargeMessageBox("success", msg["MSG_RECOVERY_MAIL_SENT"]);
						$form.addClass("hide");
						$("#password-new-form-token").removeClass("hide");
						$("#email").val($("#recoveremail").val());
					} else {
						showMessageBox(
							"danger",
							msg[data.errors.message] || msg["MSG_GENERIC_ERROR"]
						);
					}
				},
				error: function () {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				},
			});
		},
	});

	$("#password-new-form-token").validate({
		rules: {
			token: {
				required: true,
			},
			password: {
				required: true,
			},
		},
		messages: {
			token: {
				required: msg["ERR_MISSINGFIELDS"],
			},
			password: {
				required: msg["ERR_MISSINGFIELDS"],
			},
		},
		submitHandler: function (form) {
			const $form = $(form);

			$.ajax({
				method: $form.attr("method"),
				url: $form.attr("action"),
				data: $form.serialize(),
				dataType: "json",
				success: function (data) {
					if (data.success) {
						showMessageBox("success", msg["MSG_PASSWORD_UPDATED"]);
						setTimeout(() => {
							let url = window.location.href.replace(/https?:\/\//, "");
							let parts = url.split("/");
							parts[2] = "login";
							window.location.href = `https://${parts.join("/")}`;
						}, 3000);
					} else {
						if (data.errors.message == "fault") {
							showMessageBox("danger", msg["ERR_WRONG_CODE"]);
						} else {
							showMessageBox(
								"danger",
								msg[data.errors.message] || msg["MSG_GENERIC_ERROR"]
							);
						}
					}
				},
				error: function () {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				},
			});
		},
	});

	if (getUrlParam("token") != undefined) {
		$("#token").val(getUrlParam("token"));
		$("#token").attr("readonly", "readonly");
	}
	if (getUrlParam("email") != undefined) {
		$("#password-new-form-email").addClass("hide");
		$("#password-new-form-token").removeClass("hide");
		$("#recoveremail").val(decodeURIComponent(getUrlParam("email")));
		$("#email").val(decodeURIComponent(getUrlParam("email")));
		$("#email").parents(".label-input").removeClass("hide");
	}
});

function GetUrlValue(VarSearch) {
	var SearchString = window.location.search.substring(1);
	var VariableArray = SearchString.split("&");
	for (var i = 0; i < VariableArray.length; i++) {
		var KeyValuePair = VariableArray[i].split("=");
		if (KeyValuePair[0] == VarSearch) {
			return KeyValuePair[1];
		}
	}
}
