$(function () {
	$(".hotspot-container").hotSpot({
		// default selectors
		mainselector: ".hotspot-container",
		selector: ".hot-spot",
		imageselector: ".img-responsive",
		tooltipselector: ".tooltip",

		// or 'click'
		bindselector: "hover",
	});

	/*var iframe = document.querySelector("iframe");

    if (iframe != null) {
        
    var player = new Vimeo.Player(iframe);

    player.on("ended", function() {

        $('#home-video').toggleClass('hide');
        $('#post-video').toggleClass('hide');

    });

    } */

	if (typeof hotspots !== "undefined")
		for (var i = 0; i < hotspots.length; i++) {
			hotspots[i]();
		}

	$("#product-slider-best").slick({
		dots: true,
		arrows: true,
		infinite: true,
		speed: 1500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					arrows: true,
					slidesToScroll: 1,
					infinite: true,
					autoplay: true,
					autoplaySpeed: 2000,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					arrows: false,
					autoplay: true,
					autoplaySpeed: 2000,
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					slidesToShow: 1,
					autoplay: true,
					autoplaySpeed: 2000,
					slidesToScroll: 1,
				},
			},
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		],
	});
	$(".product-slider-best").slick({
		dots: true,
		arrows: false,
		infinite: true,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 4,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					autoplay: true,
					autoplaySpeed: 2000,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					autoplay: true,
					autoplaySpeed: 2000,
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					autoplay: true,
					autoplaySpeed: 2000,
					slidesToScroll: 2,
				},
			},
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		],
	});

	if ($(".video").length > 0) {
		$(".video").height($(".video").width() / 1.723);
	}

	$(".embed-container img").on("click", function () {
		const iframe = $(this).siblings("iframe");
		const src = iframe.data("src");
		iframe.attr("src", src).css("visibility", "visible")[0].click();
		$(this).remove();
		loadScript("https://player.vimeo.com/api/player.js").then(function () {
			const player = new Vimeo.Player(iframe[0]);
			player.play();
		});
	});
});
