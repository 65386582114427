$(function () {
	$("#close-modal-wishlist").on("click", function () {
		$("#shareWishlistPopup").foundation("close");
	});

	$(".carrier-form").change(function () {
		$.ajax({
			type: $(this).attr("method"),
			url: $(this).attr("action"),
			data: {
				country_id: $(this).find("select[name=country_id]").val(),
				carrier_id:
					$(this).find("input[name=carrier_id]:checked").val() != null
						? $("input[name=carrier_id]:checked").val()
						: 0,
			},
			dataType: "json",
			success: function (data) {
				if (data.success) {
					window.location.href = window.location.pathname;
				} else {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				}
			},
			error: function () {
				showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
			},
		});
	});

	// update cart quantity
    $('.cart-update [name*="qty_model"]').on('change', function() {
        $(this).closest('.cart-update').trigger('submit');
    });

	$(".cart-update").on("submit", function (event) {
		event.preventDefault();
		const $form = $(this);
		$.ajax({
			type: $form.attr("method"),
			url: $form.attr("action"),
			data: $form.serialize(),
			dataType: "json",
			success: function (data) {
				if (data.success) {
					rewixUpdateCart($form);
					window.location.href = window.location.pathname;
				}
                else if (data.code == 'MIXED_INTANGIBLE') {
                    showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                }
                else if (data.code == 'MIXED_CART') {
                    showMessageBox('danger', msg['MSG_MIXED_CART']);
                }
                else if (data.code == 'INSUFFICIENT_STOCK') {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
                else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                    showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                }
                else if (data.code == 'LOGIN_REQUIRED') {
                    location.href = '/' + $('html').attr('lang') + '/login';
                }
                else {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				}
			},
			error: function () {
				showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
			},
		});
	});

	$("button.btn-cart-save-for-later").on("click", function (event) {
		event.preventDefault();
		const $this = $(this);
        const name = $this.data("name");
        
        const cartProduct = $this.closest('.cart-product');
        cartProduct.css({
            'pointer-events': 'none',
            'opacity': '.5'
        });
        
        let payload = {};
        payload[name] = 1;
        new Promise((resolve, reject) => {
            $.ajax({
                type: "POST",
                url: $this.data("action") + '?wishlist=true',
                data: payload,
                dataType: "json",
                success: function (data) {
                    if (data.success) {
                        if (window.dataLayer) {
                            const modelId = name.replace('qty_model_', '');
                            dataLayer.push({ ecommerce: null });
                            dataLayer.push({
                                event: 'add_to_wishlist',
                                ecommerce: window.ecommerce[modelId],
                                eventCallback: resolve
                            });
                        }
                        else {
                            resolve();
                        }
                    }
                    else {
                        reject(data.code);
                    }
                },
                error: function () {
                    reject();
                }
            });
        })
        .then(() => {
            payload[name] = 0;
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: "POST",
                    url: $this.data("action"),
                    data: payload,
                    dataType: "json",
                    success: function (data) {
                        if (data.success) {
                            const container = $this.closest('.cart-product');
                            rewixUpdateCart(container, true);
                            resolve();
                        }
                        else {
                            reject(data.code);
                        }
                    },
                    error: function () {
                        reject();
                    }
                });
            })
        })
        .then(() => {
            const avg_wps = 250 / 60;
            const words = msg['MSG_SAVE_FOR_LATER'].split(/\s+/).length;
            const duration = Math.max(1500, (words / avg_wps) * 1500);
            showMessageBox('success', msg['MSG_SAVE_FOR_LATER'].replace('{0}', $this.data('nexturl') ?? '/'), null, () => {
                window.location.href = window.location.pathname;
            }, duration);
        })
        .catch((reason) => {
            if (reason == "INSUFFICIENT_STOCK") {
                showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
            }
            else {
                showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
            }
        })
        .finally(() => {
            cartProduct.css({
                'pointer-events': '',
                'opacity': ''
            });
        });
	});

	// remove cart size
	$(".btn-cart-remove").on("click", function (event) {
		event.preventDefault();
		let data = {};
		const $this = $(this);
		data[$this.data("name")] = 0;
		$.ajax({
			type: "POST",
			url: $this.data("action"),
			data: data,
			dataType: "json",
			success: function (data) {
				if (data.success) {
					const form = $this.closest("form");
					rewixUpdateCart(form, true);
					window.location.href = window.location.pathname;
				} else if (data.code == "INSUFFICIENT_STOCK") {
					showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
				} else {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				}
			},
			error: function () {
				showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
			},
		});
	});

	$(".btn-wishlist-remove").on("click", function (event) {
		var product = $(this).parents(".wishlist-product");
		event.preventDefault();
		var oldModel = product.find("input").val();
		$.ajax({
			type: "POST",
			url: "/restful/shopping/public/checkout/addtocart?wishlist=true",
			data: "qty_model_" + oldModel + "=" + 0,
			dataType: "json",
			success: function (data) {
				window.location.href = "/current/profile/wishlist";
			},
			error: function () {
				showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
			},
		});
	});

	// remove cart item
	$(".btn-cart-remove-all").on("click", function (event) {
		event.preventDefault();
		const $form = $(this);
		var currentTable = $form.attr("data-product-id");
		var inputs = $(currentTable).find(".i-number");
		var data = {};

		inputs.each(function () {
			data[$form.attr("name")] = 0;
		});

		$.ajax({
			type: "POST",
			url: $form.attr("data-action"),
			data: data,
			dataType: "json",
			success: function (data) {
				if (data.success) {
					const container = $form.closest("#cart-container");
					rewixUpdateCart(container, true);
					window.location.href = "/current/cart";
				} else if (data.code == "INSUFFICIENT_STOCK") {
					showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
				} else {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				}
			},
			error: function () {
				showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
			},
		});
	});

	// Coupon form
	$(".burn-coupon-form").on("submit", function (event) {
		event.preventDefault();
		if ($(this).find("input[name=code]").val().length > 0) {
			$.ajax({
				type: "POST",
				url: $(this).attr("action"),
				data: $(this).serialize(),
				dataType: "json",
				success: function (data) {
					if (data.success) {
						showMessageBox("success", msg["MSG_COUPON_VALID"]);
						window.location.href = "/current/cart";
					} else {
						showMessageBox("danger", msg["MSG_COUPON_ERROR"]);
					}
				},
			});
		} else {
			showMessageBox("danger", msg["MSG_COUPON_ERROR"]);
		}
	});

	$("#gestpay-form").on("submit", function (e) {
		e.preventDefault();
		var action = $(this).attr("action");
		$.ajax({
			type: "POST",
			url: action,
			async: false,
			beforeSend: function (xhr) {
				xhr.setRequestHeader("Accept", "application/json");
			},
			dataType: "json",
			success: function (data) {
				if (data.success) {
					window.location.href = data.message;
				}
			},
		});
	});

	// calculate each product total
	function singleProductQty() {
		$(".cart-product").each(function () {
			var inputs = $(this).find(".i-number");
			var total = 0;

			inputs.each(function () {
				total += parseInt($(this).val());
			});

			$(this).find(".single-product-qty").text(total);
		});
	}

	// calculate total quantity
	function totalQty() {
		var total = 0;

		$(".single-product-qty").each(function () {
			total += parseInt($(this).text());
		});

		$("#total-qty").text(total);
	}

	// calculate total quantity
	function totalDiscount() {
		var total = 0;

		$(".discount-value").each(function () {
			var currentValue = parseFloat($(this).text()).toFixed(2);
			total = parseFloat(total) + parseFloat(currentValue);
		});

		$("#total-discount").text(parseFloat(total).toFixed(2) + "€");
	}

	singleProductQty();
	totalQty();
	totalDiscount();

	$(".close-popup").click(function () {
		$("#addToCartComplete").foundation("close");
	});
});
