$(function () {
	if ($(".lightgallery") != undefined) {
		for (i = 0; i < document.getElementsByClassName("lightgallery").length; i++) {
			lightGallery(document.getElementsByClassName("lightgallery")[i]);
		}
	}

	$(".blog-date-archive").on("click", function () {
		var $this = $(this);
		var totalPress = document.getElementsByClassName("blog-date").length;
		for (i = 0; i < totalPress; i++) {
			if (
				document.getElementsByClassName("blog-date-year")[i].innerHTML ==
				$this.html()
			) {
				document.getElementsByClassName("press")[i].classList.remove("hide");
			} else {
				document.getElementsByClassName("press")[i].classList.add("hide");
			}
		}
	});
});
