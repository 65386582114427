function showMessageBox(type, message, extra, onClose = () => {}, duration=1500) {
    var boxType = type;
    var content = "";
    var topMessage = "";
    if (extra) {
        content = "<div>" + extra.content + "</div>";
    }
    if (boxType == "danger") {
        topMessage = "ERROR";
    } else {
        topMessage = "SUCCESS";
    }
    var messageBox = `
    <div class="alert-container fade in relative">
        <div class="alert-${boxType}">
            <img id="close-error" class="close-sample" src="/skins/current-skin/images/icons/cancel.svg"></img>
            <img class="alert-image" src="/skins/current-skin/images/icons/${boxType}.svg"></img>
            <div class="alert">${message}${content}</div>
        </div>
    </div>`;

    $("div.alert-box").html(messageBox);
    $("#close-error").on("click", function () {
        $("div.alert-box").html("");
        $(".alert-box").hide();
        restorePageScroll();
        onClose();
    });

    blockPageScroll();
    $(".alert-box").show();
    
    setTimeout(function () {
        $("div.alert-box").html("");
        $(".alert-box").hide();
        restorePageScroll();
        onClose();
    }, duration);
}

function showLargeMessageBox(type, message, duration) {
    duration = duration || 10000;
    var boxType = type;
    var topMessage = "";
    if (boxType == "danger") {
        topMessage = "ERROR";
    } else {
        topMessage = "SUCCESS";
    }
    message = message
        .split("\n")
        .map((part) => `<p>${part}</p>`)
        .join("");

    var messageBox = `
        <div class="alert-container fade in relative">
            <div class="alert-${boxType}">
                <img id="close-error" class="close-sample" src="/skins/current-skin/images/icons/cancel.svg"></img>
                <img class="alert-image" src="/skins/current-skin/images/icons/${boxType}.svg"></img>
                <div class="alert">${message}</div>
            </div>
        </div>`;

    $("div.alert-box").html(messageBox);
    $("#close-error").on("click", function () {
        $("div.alert-box").html("");
        $(".alert-box").hide();
        restorePageScroll();
    });
    blockPageScroll();
    $(".alert-box").show();
    setTimeout(function () {
        $("div.alert-box").html("");
        $(".alert-box").hide();
        restorePageScroll();
    }, duration);
}

function showQuestionBox(message, extra, onConfirm, onClose=()=>{}) {
    var content = '';
    if (extra) {
        content = `<div>${extra.content}</div>`;
    }

    var messageBox = `
        <div class="alert-container question fade in relative">
            <div class="alert-danger">
                <img class="close-error close-sample" src="/skins/current-skin/images/icons/cancel.svg"></img>
                <img class="alert-image" src="/skins/current-skin/images/icons/danger.svg"></img>
                <div class="alert">${message + content}</div>
                <div class="flex mt-16">
                    <input type="button" class="btn btn-secondary width-100 close-button" value="${buttons_close}">
                    <input type="button" class="btn btn-primary width-100 confirm-button" value="${buttons_confirm}">
                </div>
            </div>
        </div>
    `;
    
    $('.alert-box').html(messageBox).show();
    $('body').addClass('block-scroll');

    $('.alert-box .confirm-button').on('click', () => {
        onConfirm();
        closeMessageBox();
    });
    $('.alert-box .close-error, .alert-box .close-button').on('click', () => {
        onClose();
        closeMessageBox();
    });

    function closeMessageBox() {
        $('.alert-box').html('').hide();
        $('body').removeClass('block-scroll');
    }
}

$(function () {
    var errorFound = getUrlParam("error");

    if (errorFound) {
        blockPageScroll();
        $(".alert-box").show();
        $(".close-sample").on("click", function () {
            $("div.alert-box").html("");
            $(".alert-box").hide();
            restorePageScroll();
        });
        setTimeout(function () {
            $("div.alert-box").html("");
            $(".alert-box").hide();
            restorePageScroll();
        }, 3000);
    }
});
