function catalogPage() {
    $(".nano").nanoScroller();
    $(".nano-pane").css("display", "block");
    $(".nano-slider").css("display", "block");

    window.addEventListener(
        "message",
        (event) => {
            try {
                const json = JSON.parse(event.data);
                if (json.virtualTourLink) {
                    if (Foundation.MediaQuery.is("small only")) {
                        document.getElementById("product-panel-tour").style.flex = 2;
                    } else {
                        document.getElementById("product-panel-tour").style.flex = 1;
                    }
                    document.getElementById("product-panel-tour").style.opacity = 1;
                    var newUrl = json.virtualTourLink;
                    $("#page").load(
                        newUrl + " .grid-layout",
                        function (responseTxt, statusTxt, xhr) {
                            $("#page").attr("style", "margin:20px");
                            $("#vetrine-vt-meta").removeClass("hide");
                            if (statusTxt == "error")
                                alert("Error: " + xhr.status + ": " + xhr.statusText);
                            if (statusTxt == "success") {
                                $("#product-panel-tour").scrollTop(0);
                                $("#page a").attr("target", "_blank");
                            }
                        }
                    );
                }
            } catch (e) {
                return false;
            }
        },
        false
    );

    $("#close-product-popup").on("click", function () {
        document.getElementById("product-panel-tour").style.flex = 0;
        document.getElementById("product-panel-tour").style.opacity = 0;
    });

    function setCatalogLayout(layout) {
        if (checkCookie('catalog-layout') != layout) {
            createCookie('catalog-layout', layout);
            reloadCatalog(window.location.href, true);
        }
    }

    if (Foundation.MediaQuery.atLeast("large")) {
        if ($(".filters-wrapper").length > 0) {
            $(".filters-wrapper").slideToggle();
            $("#arrow-filter").toggleClass("rotated");
        }
    }

    $("#toogle-filter").on("click", function () {
        $(".filters-wrapper").slideToggle();
        $("#arrow-filter").toggleClass("rotated");
    });

    // catalog filters
    $("#catalog-container").on("click", ".catalog-filter", function (event) {
        event.preventDefault();
        var url = $(this).attr("href");
        reloadCatalog(url, true);
    });

    function reloadCatalog(url, push) {
        $("#catalog-container").addClass("catalog-loading");
        $.ajax({
            url: url,
            type: "GET",
            dataType: "html",
            complete: function (jqXHR, textStatus) {
                var response = jqXHR.responseText;

                $("#catalog-container").html(
                    $("<div>").append($.parseHTML(response)).find("#catalog-wrapper")
                );

                if (push) {
                    window.history.pushState(null, document.title, url);
                }
                productComponent();
                populateRemoveFilters();
                loadQtyInputs();
                addClickEvent();
                setupItems();
                $(".filters-wrapper").show();
                $("#catalog-container").removeClass("catalog-loading");
                $("html, body").removeClass("is-popup-open");
                $(".nano").nanoScroller();
                $(".nano-pane").css("display", "block");
                $(".nano-slider").css("display", "block");

                $("#catalog-wrapper").on(
                    "click",
                    "#filters-dropdown .apply-size-filter",
                    function () {
                        var tags = {};
                        var baseUrl = $(this).prev().children("ul").data("baseurl");

                        $(this)
                            .prev()
                            .find(".active")
                            .each(function () {
                                var liTag = $(this);
                                do {
                                    var tagId = "size";
                                    var tagArray = tags[tagId];
                                    if (!tagArray) {
                                        tagArray = [];
                                        tags[tagId] = tagArray;
                                    }
                                    var tagValue = $(liTag).data("tagvalue");

                                    tagArray.push(tagValue);

                                    if ($(liTag).parent().data("baseurl")) {
                                        liTag = false;
                                    } else {
                                        liTag = liTag.parent().closest("li");
                                    }
                                } while (liTag && liTag.length > 0);
                            });

                        var url;
                        if (Object.keys(tags).length > 0) {
                            if (baseUrl.indexOf("?") < 0) {
                                url = baseUrl + "?" + jQuery.param(tags, true);
                            } else {
                                url = baseUrl + "&" + jQuery.param(tags, true);
                            }
                        } else {
                            url = baseUrl;
                        }

                        reloadCatalog(url, true);
                    }
                );

                $("#catalog-wrapper").on(
                    "click",
                    "#filters-dropdown .apply-filter",
                    function () {
                        var tags = {};
                        var baseUrl = $(this).prev().children("ul").data("baseurl");

                        $(this)
                            .prev()
                            .find(".active")
                            .each(function () {
                                var liTag = $(this);
                                do {
                                    var tagId = "tag_" + $(liTag).parent().data("tagid");
                                    var tagArray = tags[tagId];
                                    if (!tagArray) {
                                        tagArray = [];
                                        tags[tagId] = tagArray;
                                    }
                                    var tagValue = $(liTag).data("tagvalue");
                                    if (tagArray.indexOf(tagValue) < 0) {
                                        tagArray.push(tagValue);
                                    }
                                    if ($(liTag).parent().data("baseurl")) {
                                        liTag = false;
                                    } else {
                                        liTag = liTag.parent().closest("li");
                                    }
                                } while (liTag && liTag.length > 0);
                            });

                        var url;
                        if (Object.keys(tags).length > 0) {
                            if (baseUrl.indexOf("?") < 0) {
                                url = baseUrl + "?" + jQuery.param(tags, true);
                            } else {
                                url = baseUrl + "&" + jQuery.param(tags, true);
                            }
                        } else {
                            url = baseUrl;
                        }

                        reloadCatalog(url, true);
                    }
                );

                $("#catalog-wrapper").on(
                    "click",
                    "#filters-dropdown .toggle-subfilter",
                    function (event) {
                        event.preventDefault();
                        $(this).parent("li").toggleClass("open");
                        $(this).closest(".scroll-view").nanoScroller({ alwaysVisible: true });
                        $(".nano-pane").css("display", "block");
                        $(".nano-slider").css("display", "block");
                        return false;
                    }
                );
            },
        });
    }

    function setupItems() {
        $(".add-to-wishlist-catalog").on("click", function () {
            const model = $(this).children("input");
            addToWishlist(model, 1, msg["MSG_ADDED_TO_WISHLIST"]);
        });
        $(".remove-from-wishlist-catalog").on("click", function () {
            const model = $(this).children("input");
            addToWishlist(model, 0, msg["MSG_REMOVED_FROM_WISHLIST"]);
        });

        $("#catalog-container .prompt-delivery").each(function () {
            $(this).siblings(".size").trigger("click");
        });
    }

    function resetDropdown($excluded) {
        var $dropDowns = $("#catalog-wrapper #filters-dropdown .filter-dropdown").not(
            $excluded
        );
        $dropDowns.slideUp();
        $dropDowns.parent().removeClass("open");
        $dropDowns.find(".active").not(".active-original").removeClass("active");
        $dropDowns.find(".active-original").not(".active").addClass("active");
    }

    function populateRemoveFilters() {
        $(".filters-wrapper li.active > a").each(function () {
            var id = $(this).data("ref");
            var url = $(this).attr("href");
            $(document.getElementById(id)).attr("href", url);
        });
        $(".filters-wrapper > a").each(function () {
            if ($(this).attr("href") === "#") {
                $(this).addClass("disabled");
                $(this).children("span.fa").remove();
            }
        });
        $('.current-filter').on('click', function (event) {
            event.preventDefault();
            const url = $(this).attr('href');
            reloadCatalog(url, true);
        });
    }

    function addClickEvent() {
        $("#filters-trigger").on("click", function () {
            $(".filters-wrapper").slideToggle();
            $("body").toggleClass("open-filter");
        });

        $(".dropdown-slide").on("click", function () {
            $(this).siblings(".subfilters-list").slideToggle();
            $(this).children("img").toggleClass("hide");
        });

        $('#layout-options input[type=radio]').on("click", function () {
            const layout = $(this).val();
            setCatalogLayout(layout);
        });
    }

    $("html").on("click", function () {
        resetDropdown($(this).parents(".filter-dropdown"));
    });

    $("#catalog-container").on(
        "click",
        "#filters-dropdown .filter-name",
        function () {
            var $thisScrollView = $(this).next(".filter-dropdown");
            resetDropdown($thisScrollView);
            if (!$(this).parent().hasClass("open")) {
                $thisScrollView.slideDown();
                $(this).parent().addClass("open");
                window.dispatchEvent(new Event("resize")); //Hack to make nanoscroller work
            } else {
                $thisScrollView.slideUp();
                $(this).parent().removeClass("open");
            }
            return false;
        }
    );

    $("#catalog-wrapper").on(
        "click",
        "#filters-dropdown .apply-filter",
        function () {
            var tags = {};
            var baseUrl = $(this).prev().children("ul").data("baseurl");

            $(this)
                .prev()
                .find(".active")
                .each(function () {
                    var liTag = $(this);
                    do {
                        var tagId = "tag_" + $(liTag).parent().data("tagid");
                        var tagArray = tags[tagId];
                        if (!tagArray) {
                            tagArray = [];
                            tags[tagId] = tagArray;
                        }
                        var tagValue = $(liTag).data("tagvalue");
                        if (tagArray.indexOf(tagValue) < 0) {
                            tagArray.push(tagValue);
                        }
                        if ($(liTag).parent().data("baseurl")) {
                            liTag = false;
                        } else {
                            liTag = liTag.parent().closest("li");
                        }
                    } while (liTag && liTag.length > 0);
                });

            var url;
            if (Object.keys(tags).length > 0) {
                if (baseUrl.indexOf("?") < 0) {
                    url = baseUrl + "?" + jQuery.param(tags, true);
                } else {
                    url = baseUrl + "&" + jQuery.param(tags, true);
                }
            } else {
                url = baseUrl;
            }

            reloadCatalog(url, true);
        }
    );

    $("#catalog-wrapper").on(
        "click",
        "#filters-dropdown .apply-size-filter",
        function () {
            var tags = {};
            var baseUrl = $(this).prev().children("ul").data("baseurl");

            $(this)
                .prev()
                .find(".active")
                .each(function () {
                    var liTag = $(this);
                    do {
                        var tagId = "size";
                        var tagArray = tags[tagId];
                        if (!tagArray) {
                            tagArray = [];
                            tags[tagId] = tagArray;
                        }
                        var tagValue = $(liTag).data("tagvalue");

                        tagArray.push(tagValue);

                        if ($(liTag).parent().data("baseurl")) {
                            liTag = false;
                        } else {
                            liTag = liTag.parent().closest("li");
                        }
                    } while (liTag && liTag.length > 0);
                });

            var url;
            if (Object.keys(tags).length > 0) {
                if (baseUrl.indexOf("?") < 0) {
                    url = baseUrl + "?" + jQuery.param(tags, true);
                } else {
                    url = baseUrl + "&" + jQuery.param(tags, true);
                }
            } else {
                url = baseUrl;
            }

            reloadCatalog(url, true);
        }
    );

    $("#catalog-wrapper").on(
        "click",
        "#filters-dropdown .toggle-subfilter",
        function (event) {
            event.preventDefault();
            $(this).parent("li").toggleClass("open");
            $(this).closest(".scroll-view").nanoScroller({ alwaysVisible: true });
            $(".nano-pane").css("display", "block");
            $(".nano-slider").css("display", "block");
            return false;
        }
    );

    $("#catalog-container").on(
        "click",
        "#filters-dropdown li > a",
        function (event) {
            var count = $("#filters-dropdown li.active").length;
            if (count >= 12 && !$(this).parent().hasClass("active")) {
                showMessageBox("warning", general_toomanyfilters);
                $("#filters-dropdown").focus();
            } else {
                $(this).parent().toggleClass("active");
            }
            return false;
        }
    );

    populateRemoveFilters();
    loadQtyInputs();
    addClickEvent();
    setupItems();
}

function getUrlParam(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
}

function addToWishlist(model = $(), quantity = 0, message = "") {
	$.ajax({
		type: "POST",
		url: "/restful/shopping/public/checkout/addtocart?wishlist=true",
		data: `qty_model_${model.val()}=${quantity}`,
		dataType: "json",
		success: function (data) {
			if (data.success) {
				showMessageBox("success", message);

                if (window.dataLayer) {
                    const modelId = model.val();
                    dataLayer.push({ ecommerce: null });
                    dataLayer.push({
                        event: 'add_to_wishlist',
                        ecommerce: window.ecommerce[modelId]
                    });
                }

				const container = model.closest(".product-container, .product-item");
				if (quantity > 0) {
					container
						.find(".div-to-wishlist, .add-to-wishlist-catalog")
						.addClass("hide");
					container
						.find(".div-already-wishlist, .remove-from-wishlist-catalog")
						.removeClass("hide");
				}
                else {
					container
						.find(".div-to-wishlist, .add-to-wishlist-catalog")
						.removeClass("hide");
					container
						.find(".div-already-wishlist, .remove-from-wishlist-catalog")
						.addClass("hide");
				}

                if (exists(container.find('.wishlist-models'))) {
                    const wishlistModels = container.find('.wishlist-models');
                    if (quantity > 0) {
                        wishlistModels.val(
                            (wishlistModels.val() + ` ${model.val()}`).trim()
                        );
                    }
                    else {
                        wishlistModels.val(
                            (wishlistModels.val().replace(model.val(), '')).trim()
                        );
                    }
                }

				$(".header-utilities__wishlist").load(
					window.location.href + " .header-utilities__wishlist > *"
				);
			} else {
				showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
			}
		},
		error: function () {
			showMessageBox("danger", msg["MSG_INSUFFICIENT_STOCK"]);
		},
	});
}
